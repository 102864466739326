
input[type=range] {
    -webkit-appearance: none;
    margin: 8px 0;
    width: 100%;
    background: transparent; /*disable background on Mozilla*/
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0 0 0 #000000, 0 0 0 #0d0d0d;
    background: #F87A38;
    border-radius: 0;
    border: 0 solid #010101;
}
input[type=range]::-webkit-slider-thumb::after {
    box-shadow: 0 10px 0 #000000, 0 0 0 #0d0d0d;
}
input[type=range]::-webkit-slider-thumb {
    box-shadow: 0 0 0 #000000, 0 0 0 #0d0d0d;
    border: 0 solid #000000;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    background: #F87A38;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #F87A38;
}
input[type=range]::-moz-range-progress {
    background: #F87A38;
    border-radius: 0;
}
input[type=range]::-moz-range-track {
    width: 100%;
    cursor: pointer;
    background: white;
    border-radius: 0;
}
input[type=range]::-moz-range-thumb {
    border: 0 solid #000000;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    background: #F87A38;
    cursor: pointer;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 8px 0;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #F87A38;
    border: 0 solid white;
    border-radius: 0;
    box-shadow: 0 0 0 #000000, 0 0 0 #0d0d0d;
}
input[type=range]::-ms-fill-upper {
    background: white;
    border: 0 solid white;
    border-radius: 0;
    box-shadow: 0 0 0 #000000, 0 0 0 #0d0d0d;
}
input[type=range]::-ms-thumb {
    box-shadow: 0 0 0 #000000, 0 0 0 #0d0d0d;
    border: 0 solid #000000;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    background: #F87A38;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #F87A38;
}
input[type=range]:focus::-ms-fill-upper {
    background: white;
}
