@keyframes lampAnimation {
    0%{
    }
    50%{
        opacity: 0.2;
    }
}

.animateLamp {
    animation: lampAnimation 0.75s infinite 0s ease;
}

