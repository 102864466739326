input.slot-input[type="text"] {
    padding-inline-start: 0;
    -moz-appearance: textfield;
    -webkit-appearance: none;
    background: url("../images/SquareOrangeGroup.svg");
    background-position:center; 
    background-size: cover;
    border: 0;
    margin: 0.25rem;
    margin-left: 1.1rem;
    font-family: "Courier New", Courier, monospace;
    color: transparent;
    text-shadow: 0 0 0 #ffffff;  
    line-height: 1;
    padding-left: 0;
}

input.desktop[type="text"] {
    height: 6.4rem;
    width: 32rem;
    font-size: 5.52rem;
    letter-spacing: 1.9rem; 
}

input.mobile[type="text"] {
    height: 4.4rem;
    width: 21rem;
    font-size: 3.8rem;
    letter-spacing: 1.1rem; 
}

input[type=text].slot-input::-webkit-inner-spin-button,
input[type=text].slot-input::-webkit-outer-spin-button
{
    -webkit-appearance: none;
    margin: 0;
}

input.slot-input:focus {
    outline: none;
}
