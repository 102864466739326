
@keyframes pinsAnimation {
    0%{
    }
    50%{
        transform: translateY(-3%);
    }
}

@keyframes cloudAnimation {
    0%{
    }
    50%{
        transform: translateX(-10%);
    }
}

@keyframes sunAnimation {
    0%{
    }
    50%{
        transform: rotate(10deg);
    }
}

.animateCloud {
    animation: cloudAnimation 6s infinite 0s ease-in-out;
}

.animatePins {
    animation: pinsAnimation 3s infinite 0s ease-in-out;
}

.animateSun {
    animation: sunAnimation 10s infinite 0s ease-in-out;
}
