.money-fade {
    animation: money-fade 1s ease;
}

@keyframes money-fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.unselectable {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}