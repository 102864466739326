.warning-fade {
    animation: warning-fade 1s ease;
}

@keyframes warning-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

 